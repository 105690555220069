import { createTRPCProxyClient, httpLink } from '@trpc/client';
import { createTRPCNext } from '@trpc/next';
import superjson from 'superjson';
import type { AppRouter } from '../server/routers/_app';

function getBaseUrl() {
  if (typeof window !== 'undefined')
    // browser should use relative path
    return '';

  if (process.env.VERCEL_URL)
    // reference for vercel.com
    return `https://${process.env.VERCEL_URL}`;

  if (process.env.RENDER_INTERNAL_HOSTNAME)
    // reference for render.com
    return `http://${process.env.RENDER_INTERNAL_HOSTNAME}:${process.env.PORT}`;

  // assume localhost
  return `http://localhost:${process.env.PORT ?? 3000}`;
}

const config = {
  transformer: superjson,
  links: [
    httpLink({
      url: `${getBaseUrl()}/api/v1`,
    }),
  ],
};

export const trpc = createTRPCNext<AppRouter>({
  config() {
    return config;
  },
});

// For use outside react
export const trpcVanilla = createTRPCProxyClient<AppRouter>(config);
