import { SessionProvider } from 'next-auth/react';
import * as Sentry from '@sentry/nextjs';
import clsx from 'clsx';
import { useAtom } from 'jotai';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { Router, useRouter } from 'next/router';
import Script from 'next/script';
import { QueryClient, QueryClientProvider } from 'react-query';
import Error from '../components/Error';
import Footer from '../components/layout/Footer';
import NavBar from '../components/layout/NavBar';
import Notification from '../components/layout/Notification';
import ScrollToTop from '../components/shared/ScrollToTop';
import WithRouteGuard from '../components/shared/WithRouteGuard';
import WithAuth from '../components/shared/withAuth';
import LoggedOutNav from '../components/signUp/LoggedOutNav';
import useAdmin from '../hooks/useAdmin';
import useInApp from '../hooks/useInApp';
import useScroll from '../hooks/useScroll';
import { analytics } from '../services/analytics';
import { isLocal } from '../store/store';
import { colorManagerAtom } from '../store/uiStore';
import '../styles/global.css';
import { trpc } from '../utils/trpc';

let timeout;

const sendPageEvent = () => {
  // Only send pageview event from the browser
  if (typeof window === 'undefined') return;

  // don't send pageview event for universe pages
  const { pathname } = window.location;
  if (pathname.startsWith('/universe')) return;
  clearTimeout(timeout);
  timeout = setTimeout(() => {
    analytics?.page();
  }, 500);
};

const handleRouteChange = () => {
  sendPageEvent();
};

Router.events.on('routeChangeComplete', handleRouteChange);

// Trigger the initial page event outside the event handler
if (typeof window !== 'undefined') {
  sendPageEvent();
}

const queryClient = new QueryClient();

function MyApp({ Component, pageProps }: AppProps) {
  const [colorManager] = useAtom(colorManagerAtom);
  const { inApp, inAccount, inCheckout, inAdmin, inUniverse, inProjects } = useInApp();
  const { pathname } = useRouter();
  useScroll(true);

  // _app useAdmin is the controller for admin mode toggle
  useAdmin(true);

  //check if url ends with /update-billing - this is specifically for Churnbuster
  const isBilling = pathname.endsWith('/update-billing');
  if (isBilling) {
    return <Component />;
  }

  const showFooter = !inAccount && !inCheckout && !inAdmin;
  const showHelpscout = !isLocal;
  return inUniverse ? (
    <Component {...pageProps} />
  ) : (
    <>
      <Head>
        <link rel="shortcut icon" href="favicon.ico" />
        <title>DEATH TO STOCK®</title>

        <meta name="pinterest" content="nopin" />

        <meta property="og:url" content="https://app.deathtothestockphoto.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Death to Stock - When stock dies art thrives." />
        <meta
          property="og:image"
          content="https://deathtothestockphoto.com/wp-content/uploads/2022/05/dts-og-image.jpg"
        />
        <meta
          property="og:description"
          content="Death to the Stock Photo is a unique stock photo resource for creatives looking to stand out. "
        />
        <meta property="og:site_name" content="Death to the Stock Photo" />

        <meta property="og:locale" content="en_US" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content="https://app.deathtothestockphoto.com/" />
        <meta
          name="twitter:title"
          content="Death to Stock - Authentic stock photos for non-stock creatives."
        />
        <meta
          name="twitter:description"
          content="Death to the Stock Photo is a unique stock photo resource for creatives looking to stand out. "
        />
        <meta name="twitter:creator" content="@deathtostock" />
        <meta
          name="twitter:image"
          content="https://deathtothestockphoto.com/wp-content/uploads/2022/05/dts-og-image.jpg"
        />
      </Head>

      <div
        className={clsx(
          colorManager.background,
          colorManager.text,
          'animate-fadeIn transition-colors duration-[300ms] ease-out'
        )}
      >
        {/* Handle any errors here */}
        <Sentry.ErrorBoundary fallback={Error}>
          <QueryClientProvider client={queryClient}>
            <SessionProvider session={pageProps.session}>
              <WithAuth>
                <WithRouteGuard>
                  <div className={`mx-auto min-h-screen min-w-[1280px] px-[64px]`}>
                    {inApp ? <NavBar gradient={inProjects} /> : <LoggedOutNav />}
                    <Component {...pageProps} />
                  </div>

                  {inApp && (
                    <>
                      <ScrollToTop />
                      {showFooter && <Footer />}
                    </>
                  )}
                </WithRouteGuard>
              </WithAuth>
            </SessionProvider>
          </QueryClientProvider>
        </Sentry.ErrorBoundary>
      </div>
      <Notification />
      {/* Load Helpscout script */}
      {showHelpscout && (
        <Script
          id="helpscout-script"
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `
                        !(function (e, t, n) {
                            function a() {
                                var e = t.getElementsByTagName('script')[0],
                                    n = t.createElement('script');
                                (n.type = 'text/javascript'), (n.async = !0), (n.src = 'https://beacon-v2.helpscout.net'), e.parentNode.insertBefore(n, e);
                            }
                                            if (
                        ((e.Beacon = n =
                        function (t, n, a) {
                            e.Beacon.readyQueue.push({ method: t, options: n, data: a });
                                                    }),
                        (n.readyQueue = []),
                        'complete' === t.readyState)
                        )
                        return a();
                        e.attachEvent ? e.attachEvent('onload', a) : e.addEventListener('load', a, !1);
                        })(window, document, window.Beacon || function () { });
                    `,
          }}
        />
      )}
      {/* Load Microsoft Clarity */}
      {!isLocal && (
        <Script
          id="clarity-script"
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `
                        (function(c,l,a,r,i,t,y){
                            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                        })(window, document, "clarity", "script", "${process.env.NEXT_PUBLIC_CLARITY_ID}");
                    `,
          }}
        />
      )}
      {/* Churnkey Script to handle cancel flow */}
      <Script
        id="churnkey script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
        !function(){
          if (!window.churnkey || !window.churnkey.created) {
            window.churnkey = { created: true };
            const a = document.createElement('script');
            a.src = 'https://assets.churnkey.co/js/app.js?appId=dbaty5xe3';
            a.async = true;
            const b = document.getElementsByTagName('script')[0];
            b.parentNode.insertBefore(a, b);
          }
        }();
      `,
        }}
      />
    </>
  );
}

export default trpc.withTRPC(MyApp);
