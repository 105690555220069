import { atom } from 'jotai';
import { Notification } from '../interfaces';

export const singlePhotoModalAtom = atom(null);
export const showSearchAtom = atom(false);
export const colorManagerAtom = atom<{
  background: string;
  text: string;
  navActive: boolean;
  name: string;
}>({
  background: 'bg-black',
  text: 'text-white',
  navActive: false,
  name: '#000000',
});
export const notificationAtom = atom<Notification>({ message: '', link: '' });
export const showStickyNavAtom = atom(true);
export const minHideHeightAtom = atom(60);
export const dontChangeStickyNavAtom = atom(false);
export const slimNavAtom = atom(false);
export const searchTermAtom = atom<string | null>(null);
export const upgradeModalAtom = atom(false);

export const pageViewsAtom = atom({});

export const uiColors = [
  {
    name: '#000000',
    background: 'bg-black',
    text: 'text-white',
  },
  {
    name: '#2344BA',
    background: 'bg-[#2344BA]',
    text: 'text-white',
  },
  {
    name: '#667CCB',
    background: 'bg-[#667CCB]',
    text: 'text-white',
  },
  {
    name: '#404C25',
    background: 'bg-[#404C25]',
    text: 'text-white',
  },
  {
    name: '#8EA066',
    background: 'bg-[#8EA066]',
    text: 'text-white',
  },
  {
    name: '#9D70C0',
    background: 'bg-[#9D70C0]',
    text: 'text-white',
  },
  {
    name: '#E2B2BE',
    background: 'bg-[#E2B2BE]',
    text: 'text-white',
  },
  {
    name: '#833405',
    background: 'bg-[#833405]',
    text: 'text-white',
  },
  {
    name: '#B98B32',
    background: 'bg-[#B98B32]',
    text: 'text-white',
  },
  {
    name: '#FFFFFF',
    background: 'bg-[#FFFFFF]',
    text: 'text-black',
  },
];
