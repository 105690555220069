import { useEffect, useState } from 'react';
import useScroll from '../../hooks/useScroll';

const ScrollToTop = () => {
  const { scrollY } = useScroll();
  const [show, setShow] = useState(false);

  const handleScroll = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (scrollY > 700) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [scrollY]);

  return (
    <>
      {show && (
        <span onClick={handleScroll} className="fixed right-10 bottom-[120px] cursor-pointer z-50">
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="20" cy="20" r="20" fill="white" />
            <path d="M20 13.3838V33.3838" stroke="black" strokeWidth="1.25" />
            <path
              d="M11.668 21.7171L20.0013 13.3838L28.3346 21.7171"
              stroke="black"
              strokeWidth="1.25"
            />
            <line
              x1="11.668"
              y1="10.0469"
              x2="28.3346"
              y2="10.0469"
              stroke="black"
              strokeWidth="1.25"
            />
          </svg>
        </span>
      )}
    </>
  );
};

export default ScrollToTop;
