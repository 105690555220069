import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import useInApp from '../../hooks/useInApp';

import { trpc } from '@/utils/trpc';
import LoadingComponent from '../layout/LoadingComponent';

export default function WithRouteGuard({ children }) {
  const { pathname, replace } = useRouter();
  const { status: sessionStatus } = useSession();

  const { inApp } = useInApp();
  const { data: user } = trpc.users.getAuthenticatedUser.useQuery(undefined, {
    staleTime: Infinity,
  });

  const [component, setComponent] = useState(inApp ? <LoadingComponent /> : children);

  // If this is a protected routed run the checks before showing children
  const [showChildren, setshowChildren] = useState(!inApp);

  useEffect(() => {
    if (
      (pathname.includes('sign-up') || pathname.includes('login')) &&
      sessionStatus === 'authenticated'
    ) {
      return redirect('/');
    }

    if (!inApp || sessionStatus === 'authenticated') {
      setshowChildren(true);
    }

    // If they are not logged in then redirect them
    if (inApp && sessionStatus === 'unauthenticated') {
      return redirect('/login');
    }

    // valid user but no subscription - can only access account -> profile, plan and billing
    if (user && !user.subscription_status) {
      if (
        (!pathname.includes('/account/') || pathname.includes('/account/history')) &&
        !pathname.includes('/wallet')
      ) {
        replace('/account/plan');
        return;
      }
    }

    // Redirect non admins away from admin
    if (user && !user!.is_admin) {
      if (pathname.includes('/admin')) {
        replace('/');
        return;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inApp, sessionStatus, pathname, user]);

  function redirect(path: string) {
    replace(path);
    return setComponent(<LoadingComponent />);
  }

  // If we are loading this on the server then useEffect wont run
  // So we check if the route is projected and just return a loading screen and handle the logic once the client loads
  if (typeof window === undefined && inApp) return <LoadingComponent />;

  return showChildren ? children : component;
}
