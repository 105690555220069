import clsx from 'clsx';
import { useAtom } from 'jotai';
import Link from 'next/link';
import { useMemo } from 'react';
import { colorManagerAtom } from '../../store/uiStore';
import useMediaSearch from '@/hooks/useMediaSearch';
import _ from 'lodash';
import { useRouter } from 'next/router';

const Footer = () => {
  const [colorManager] = useAtom(colorManagerAtom);
  const router = useRouter();

  const { medias } = useMediaSearch('', 'Footer search', {
    filters: 'media_type:photo AND orientation:landscape AND published=1',
  });

  const currentImages = useMemo(() => {
    if (medias) {
      return _.shuffle(medias).slice(0, 6);
    } else {
      return [];
    }
  }, [medias.length, router.pathname]);

  const currentYear = new Date().getFullYear();

  const mainLinks = [
    {
      link: '/projects',
      text: 'Projects',
    },
    {
      link: '/explore',
      text: 'Explore',
    },
    {
      link: '/collective',
      text: 'Collective',
    },
    {
      link: '/moodboard',
      text: 'Moodboard',
    },
    {
      link: '/account',
      text: 'Account',
    },
  ];

  const socialLinks = [
    {
      link: 'https://www.instagram.com/deathtostock/',
      text: 'IG',
    },
    {
      link: 'https://www.facebook.com/deathtostockphotos/',
      text: 'FB',
    },
    {
      link: 'https://twitter.com/deathtostock',
      text: 'TW',
    },
    {
      link: 'https://open.spotify.com/user/deathtostock?si=laa4F9YiRZSWYtsxd1EPmw',
      text: 'SPOT',
    },
    {
      link: 'https://www.tiktok.com/@dtstrends',
      text: 'TIKTOK',
    },
  ];

  return (
    <div
      className={clsx([
        'border-t pt-16',
        colorManager.text === 'text-black' ? 'border-black' : 'border-white',
      ])}
    >
      <div className="mx-auto px-[130px]">
        <div className="grid grid-cols-2 gap-32">
          <div className="grid grid-cols-2">
            <div className="w-[58px]">
              <svg
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 310 110"
                fill={colorManager.text === 'text-black' ? 'black' : 'white'}
              >
                <path d="M35.9 19.5h34.2c25.7 0 41.4 12.3 41.4 35.5 0 23.6-15.8 35.5-41.4 35.5H35.9v-71zm34.3 53.6c14.2 0 20.9-5.7 20.9-18.1 0-12.7-7-18.1-20.9-18.1H56.4v36.2h13.8zm73.4-36.2h-31.5V19.5h83.6V37h-31.6v53.6h-20.5V36.9zm52.6 30.2h21.9c-.2 4.8 6.4 7.6 17.7 7.6 13.6 0 21.6-2.2 21.6-6.1 0-12.2-59 5.9-59-26.6 0-14.6 15.2-24.2 38-24.2 23.4 0 39.4 10 39.6 25.1h-21.9c.2-4.8-5.8-7.6-15.9-7.6-12 0-19.1 2.2-19.1 6.1 0 13 59-3.8 59 26.6 0 14.6-16.1 24.2-40.4 24.2-25.1 0-41.8-10-41.5-25.1z" />
                <path d="M12.7 55c0 16.2 6.9 31.4 19.1 42.9l-4.6 4.5C12.9 88.9 5.8 73.1 5.8 55c0-18.1 7.1-33.9 21.3-47.5l4.6 4.5c-12.1 11.6-19 26.8-19 43z" />
                <path d="M278.3 12l4.6-4.5C297.1 21 304.2 36.9 304.2 55s-7.1 33.9-21.3 47.5l-4.6-4.5c12.2-11.5 19.1-26.7 19.1-42.9s-7-31.5-19.1-43.1z" />
              </svg>
            </div>
            <div>
              <p className="font-nhg font-normal text-sm leading-none 3xl:text-sm 4xl:text-[15px]">
                SAY HI
              </p>
              <a
                href="mailto:squad@deathtothestockphoto.com"
                className="font-nhg font-normal text-sm leading-none 3xl:text-sm 4xl:text-[15px]"
              >
                squad@deathtothestockphoto.com
              </a>
            </div>
          </div>

          <div className="grid grid-cols-3">
            <div>
              {mainLinks.slice(0, 2).map(({ link, text }, i) => (
                <Link key={i} href={link} className="contents">
                  <span className="block font-nhg font-normal text-sm leading-none 3xl:text-sm 4xl:text-[15px] cursor-pointer mb-4">
                    {text}
                  </span>
                </Link>
              ))}
            </div>
            <div>
              {mainLinks.slice(2, 4).map(({ link, text }, i) => (
                <Link key={i} href={link} className="contents">
                  <span className="block font-nhg font-normal text-sm leading-none 3xl:text-sm 4xl:text-[15px] cursor-pointer mb-4">
                    {text}
                  </span>
                </Link>
              ))}
            </div>
            <div>
              {mainLinks.slice(4, 5).map(({ link, text }, i) => (
                <Link key={i} href={link} className="contents">
                  <span className="block font-nhg font-normal text-sm leading-none 3xl:text-sm 4xl:text-[15px] cursor-pointer mb-4">
                    {text}
                  </span>
                </Link>
              ))}
            </div>
          </div>
        </div>

        <div className="pt-16 pb-8">
          <div
            className="text-center mb-8 text-[4.54vw] font-medium font-ace-bold leading-[1.1] uppercase"
            style={{ letterSpacing: '0.2em' }}
          >
            Feeling inspired?
          </div>
          <div className="flex justify-between">
            {currentImages &&
              currentImages?.map((photo) => (
                <Link
                  href={`/photo/${photo.id}`}
                  key={photo.id}
                  className="w-[140px] h-[140px] cursor-pointer"
                >
                  {/* eslint-disable-next-line @next/next/no-img-element, jsx-a11y/alt-text */}
                  <img
                    src={photo.imgix?.links?.small_image_url ?? ''}
                    className="w-full h-full object-cover object-center"
                    onContextMenu={(e) => e.preventDefault()}
                  />
                </Link>
              ))}
          </div>

          <div className="flex justify-between font-nhg font-normal text-sm leading-none 3xl:text-sm 4xl:text-[15px] pt-32">
            <p className="font-nhg font-normal text-sm leading-none 3xl:text-sm 4xl:text-[15px]">
              ALL RIGHTS RESERVED
            </p>
            <p className="font-nhg font-normal text-sm leading-none 3xl:text-sm 4xl:text-[15px]">
              © DEATH TO STOCK {currentYear}
            </p>
            <div className="flex gap-6">
              {socialLinks.map(({ text, link }, i) => (
                <a
                  key={i}
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  className="cursor-pointer font-nhg font-normal text-sm leading-none 3xl:text-sm 4xl:text-[15px]"
                >
                  {text}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
